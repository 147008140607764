import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface IScrollRevealProps {
  children: any;
  className?: string;
}

const ScrollReveal: React.FC<IScrollRevealProps> = ({
  children,
  className = ``,
}) => {
  const [animate, setAnimate] = useState(false);
  const [ref, inView] = useInView({ threshold: 0.4 });

  useEffect(() => {
    if (inView && !animate) {
      setAnimate(true);
    }
  }, [ref, inView]);

  return (
    <div ref={ref} className={`${className}${animate ? ` fadeInUp` : ``}`}>
      {children}
    </div>
  );
};

export default ScrollReveal;
