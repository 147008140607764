import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { tabletAndBelow } from '@/app/common/style/mixins';
import { h2 } from '@/app/common/style/typography';
import ScrollReveal from '@/components/shared/ScrollReveal/ScrollReveal';

const ClientsSectionStyled = styled(ScrollReveal)`
  margin-top: 104px;
  margin-bottom: 88px;

  .clients-container {
    p {
      ${h2}
      margin-bottom: 0;
    }
  }

  .action {
    margin-top: 32px;
  }

  ${tabletAndBelow(css`
    margin-top: 80px;
    margin-bottom: 72px;
  `)}
`;

export default ClientsSectionStyled;
