import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { BaseComponentProps } from '@/types';
import ClientsSectionStyled from './ClientSectionStyled';
import ContentfulRawToComponent from '../../components/utils/ContentfulRawToComponent';
import Reveal from '@/components/reveal/Reveal';

const query = graphql`
  query homeClients {
    contentfulBlock(slug: { eq: "home-clients" }) {
      id
      content {
        raw
      }
    }
  }
`;

const ClientsSection: React.FC<BaseComponentProps> = () => {
  const { contentfulBlock } = useStaticQuery(query);
  return (
    <ClientsSectionStyled className="clients-section container">
      <Reveal className="section-header">
        <h2 className="mini-header">Some of our clients</h2>
      </Reveal>

      <Reveal className="clients-container">
        <ContentfulRawToComponent raw={contentfulBlock.content.raw} />
      </Reveal>

      <Reveal className="action">
        <Link to="/work" className="link">
          See our work
        </Link>
      </Reveal>
    </ClientsSectionStyled>
  );
};

export default ClientsSection;
